import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { Article, ArticleGroup } from '/@types/articles';
import { api, genericApiErrorHandler } from '/@utilities/api';

function mapArticles(articles: Array<any>, projectId?: number): Map<number, Article> {
  return new Map(
    articles
      .filter(({ ProductGroupName }) =>
        [ArticleGroup.Time, ArticleGroup.Utgifter].includes(ProductGroupName),
      )
      .map((article) => [
        article.Id,
        {
          articleNumber: article.ArticleNumber,
          description: article.Description,
          id: article.Id,
          name: article.Name,
          options: article.Options,
          pricePerUnit: article.PricePrUnit,
          productGroupName: article.ProductGroupName,
          sortOrder: article.SortOrder,
          unit: article.Unit,
          projectId: projectId || null,
          childArticleId: article.RelatedArticleId,

          generatedExternally: article.GeneratedExternally,

          isTime: article.Options.includes('time'),
          isLabel: article.Options.includes('label'),
          isDay: article.Unit === 'dag',
          isExpense: article.ProductGroupName.includes(ArticleGroup.Utgifter),

          requireComment: article.RequireComment,

          labels: new Set(article.Labels),
        },
      ]),
  );
}

export const useArticlesStore = defineStore('articles', () => {
  const articles = ref<Map<number, Article>>(new Map());
  const allArticles = ref<Map<number, Article>>(new Map());

  const articlesByProject = computed(
    () => (filterProjectId: number) =>
      new Map([...articles.value].filter(([id, { projectId }]) => projectId === filterProjectId)),
  );

  function loadProjectArticles(userId: number, projectId: number) {
    return api
      .get(`timer/users/${userId}/projects/${projectId}/articles`)
      .then(({ data }) => {
        articles.value = mapArticles(data);
      })
      .catch(genericApiErrorHandler);
  }

  function loadAllArticles() {
    return api
      .get(`/articles`)
      .then(({ data }) => {
        allArticles.value = mapArticles(
          data.filter(({ ProductGroupName }) =>
            ['Timesatser', 'Utgifter'].includes(ProductGroupName),
          ),
        );
      })
      .catch(genericApiErrorHandler);
  }

  return {
    articles,
    allArticles,
    articlesByProject,
    loadProjectArticles,
    loadAllArticles,
  };
});

export const moduleLinks = [
  {
    name: 'Saksbehandler',
    external: true,
    link: 'https://app.dokflyt.no',
    technicalName: 'Energi',
    id: 1,
    icon: '/images/energi-icon-05x.png',
  },
  {
    name: 'Veilys',
    link: '/veilys',
    technicalName: 'Veilys',
    id: 9,
    icon: '/images/veilys-icon-05x.png',
  },
  {
    name: 'Energi',
    link: '/energi',
    technicalName: 'Energi 2.0',
    id: 14,
    icon: '/images/energi-icon-05x.png',
  },
  {
    name: 'Byggestrøm',
    link: '/byggestrom',
    technicalName: 'Byggestrøm',
    id: 5,
    icon: '/images/byggestrøm-icon-05x.png',
  },
  {
    name: 'Kommuneoppdrag',
    link: '/kommuneoppdrag',
    technicalName: 'KommuneOppdrag',
    id: 24,
    icon: '/images/kommuneoppdrag-icon-05x.png',
  },
  {
    name: 'Henvendelse',
    link: '/henvendelse',
    technicalName: 'Henvendelse',
    id: 12,
    icon: '/images/henvendelse-icon-05x.png',
  },
  {
    name: 'Vedlikehold',
    link: '/vedlikehold',
    technicalName: 'Vedlikehold',
    id: 13,
    icon: '/images/vedlikehold-icon-05x.png',
  },
  {
    name: 'Utstyr',
    link: '/utstyr',
    technicalName: 'Utstyr',
    id: 10,
    icon: '/images/lager-icon-05x.png',
  },
  {
    name: '1-2-3',
    link: '/ett',
    technicalName: '1-2-3',
    id: 15,
    icon: '/images/123-icon-05x.png',
  },
  {
    name: 'Telekom',
    link: '/telekom',
    technicalName: 'Telekom',
    id: 22,
    icon: '/images/telekom-icon-05x.png',
  },
  {
    name: 'Jernbane',
    link: '/jernbane',
    technicalName: 'Jernbane',
    id: 25,
    icon: '/images/jernbane-icon-05x.png',
  },
  {
    name: 'Vakt og beredskap',
    link: '/beredskap',
    technicalName: 'Vakt og beredskap',
    id: 8,
    icon: '/images/beredskap-icon-05x.png',
  },
  {
    name: 'Partner',
    link: '/partner',
    technicalName: 'EnergiPartner',
    id: 4,
    icon: '/images/partner-icon-05x.png',
  },
  {
    name: 'Kommunepartner',
    link: '/kommunepartner',
    technicalName: 'KommunePartner',
    id: 23,
    icon: '/images/kommunepartner-icon-05x.png',
  },
  {
    name: 'Arkiv',
    link: '/arkiv',
    technicalName: 'Arkiv',
    id: 16,
    icon: '/images/arkiv-icon-05x.png',
  },
  {
    name: 'Oversiktslister',
    link: '/oversiktslister',
    technicalName: 'Oversiktsliste',
    id: 17,
    icon: '/images/oversiktslister-icon-05x.png',
  },
  {
    name: 'Innsikt',
    link: '/innsikt',
    technicalName: 'Innsikt',
    id: 30,
    icon: '/images/analyse-icon-05x.png',
    role: 'Analyse', // As a quick-fix we just use the same role as Analyse. This will likely change.
  },
  {
    name: 'Analyse',
    link: '/analyse',
    technicalName: 'Analyse',
    id: 21,
    icon: '/images/analyse-icon-05x.png',
    role: 'Analyse',
  },
  {
    name: 'NVDB Vegreferanse',
    link: '/nvdb-vegreferanse',
    technicalName: 'NVDB Vegreferanse',
    id: 26,
    icon: '/images/nvdb-vegreferanse-icon-05x.png',
  },
  {
    name: 'Innmåling',
    link: '/innmaling',
    technicalName: 'Innmåling',
    id: 27,
    icon: '/images/innmåling-icon-05x.png',
  },
  {
    name: 'Geobilder',
    link: '/geobilder',
    technicalName: 'GeoBilder',
    id: 28,
    icon: '/images/geobilder-icon-05x.png',
  },
  {
    name: 'Elektro',
    link: '/elektro',
    technicalName: 'El-installasjon',
    id: 29,
    icon: '/images/elektro-icon-05x.png',
  },
];

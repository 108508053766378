<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { debounce, throttle } from 'lodash-es';

type InputMode =
  | 'search'
  | 'numeric'
  | 'none'
  | 'url'
  | 'text'
  | 'decimal'
  | 'tel'
  | 'email'
  | 'time'
  | 'password';

const extract = (value: string | null, type: InputMode) => {
  switch (type) {
    case 'numeric': {
      if (!/^[0-9]*$/.test(value)) {
        const match = value.match(/[0-9]+/g);
        if (Array.isArray(match)) {
          return match.join('');
        } else {
          return '';
        }
      } else {
        return value;
      }
    }

    default:
      return value;
  }
};

const props = defineProps<{
  modelValue: string | null;
  label?: string;
  placeholder?: string;
  inputmode?: InputMode;
  elevate?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  primary?: boolean;
  active?: boolean;
  rows?: number;
  required?: boolean;
  name?: string;
  noDelay?: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'input']);

const textvalue = ref(props.modelValue);

const textarea = ref<HTMLTextAreaElement | null>(null);

const emitFn = debounce(function (value) {
  emit('input', value);
  emit('update:modelValue', value);
}, 500);

const shrink = debounce(function (el) {
  while (Number(el.rows) > props.rows && el.scrollHeight === el.clientHeight) {
    el.rows = Number(el.rows) - 1;

    if (el.scrollHeight > el.clientHeight) {
      el.rows = Number(el.rows) + 1;
      break;
    }
  }
}, 500);

const grow = throttle(
  function (el) {
    const scrollHeight = el.scrollHeight;
    while (scrollHeight > el.clientHeight) {
      el.rows = Number(el.rows) + 1;
    }
  },
  500,
  { leading: true },
);

watch(
  () => props.modelValue,
  (newValue) => {
    textvalue.value = extract(newValue, props.inputmode);
  },
);

watch(textvalue, (newValue, oldValue) => {
  if (props.multiline) {
    const el = textarea.value;

    if (oldValue == null || newValue.length >= oldValue.length) {
      grow(el);
    } else {
      shrink(el);
    }
  }
});

const type = computed(() => {
  switch (props.inputmode) {
    case 'email':
    case 'tel':
    case 'url':
    case 'time':
    case 'password':
      return props.inputmode;

    default:
      return 'text';
  }
});

const placeholderCheck = computed(() => {
  return (
    (props.inputmode === 'numeric' ? textvalue.value === null : !textvalue.value) &&
    type.value !== 'time'
  );
});

function input({ target }) {
  const { value } = target;

  switch (props.inputmode) {
    case 'numeric':
      target.value = extract(value, 'numeric');
      break;

    default:
      textvalue.value = target.value;
  }

  if (props.noDelay) {
    emit('update:modelValue', target.value);
    emit('input', target.value);
  } else {
    emitFn(target.value);
  }
}

function clear() {
  textvalue.value = '';
  emit('update:modelValue', textvalue.value);
  emit('input', textvalue.value);
}

onMounted(() => {
  if (!props.multiline) return;
  const el = textarea.value;
  el.rows = props.rows;
  grow(el);
});
</script>

<template>
  <label
    class="input"
    :class="{
      'input--elevate': elevate,
      'input--icon': $slots.icon,
      'input--search': inputmode === 'search',
      'input--disabled': disabled,
      'input--primary': primary,
      'input--active': active,
      'input--labeled': label,
    }"
  >
    <div v-if="label" class="input__label">{{ label }}</div>

    <input
      v-if="!multiline"
      v-on:input.passive="input"
      v-model="textvalue"
      class="input__input"
      :inputmode="inputmode"
      :type="type"
      :disabled="disabled"
      :required="required"
      :name="name"
    />

    <textarea
      ref="textarea"
      v-else
      v-on:input.passive="input"
      v-model="textvalue"
      class="input__input"
      :rows="rows"
      :disabled="disabled"
      :required="required"
      :name="name"
    >
    </textarea>

    <div
      class="input__placeholder"
      :style="{ visibility: placeholderCheck ? 'visible' : 'hidden' }"
    >
      {{ placeholder || (disabled ? '' : 'Skriv her') }}
    </div>

    <div class="input__actions">
      <df-button v-if="textvalue && inputmode === 'search'" v-on:click="clear()">
        <template v-slot:icon>
          <df-icon code="f00d" />
        </template>
      </df-button>
      <slot name="action" :value="textvalue"></slot>
    </div>

    <div v-if="$slots.icon" class="input__icon">
      <slot name="icon"></slot>
    </div>
  </label>
</template>

<style scoped>
.input {
  border: 1px solid var(--color-border);
  display: grid;
  width: 100%;
  color: var(--color-text);
  border-radius: var(--radius-sm);
  padding: calc(var(--gap-md) - 1px) calc(var(--gap-md) - 1px) calc(var(--gap-md) - 1px)
    calc(var(--gap-md) - 1px);

  grid-template-areas: 'label actions' 'input actions';
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-auto-columns: max-content;
  grid-column-gap: var(--gap-md);
  cursor: text;
  align-content: center;
}

.input--icon {
  grid-template-areas: 'icon label actions' 'icon input actions';
  grid-template-columns: max-content 1fr;
  grid-auto-columns: max-content;
  padding-left: calc(0.5rem - 1px);
}

.input--elevate {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
  border-color: transparent;
}

.input--elevate.input--primary {
  background-color: var(--color-primary);
  color: white;
}

.input--active {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.input--disabled {
  opacity: 0.5;
}

.input:focus-within {
  border-color: #5c6f84;
}

.input__label {
  grid-area: label;
  font-size: 0.8rem;
  font-weight: 600;
}

.input:not(.input--labeled) .input__input,
.input:not(.input--labeled) .input__placeholder {
  grid-area: label / label / input / input;
}

.input:not(.input--labeled) .input__icon {
  height: inherit;
  width: inherit;
}

.input__input {
  grid-area: input;
  background-color: transparent;
  color: inherit;
  font: inherit;
  border: none;
  display: block;
  width: 100%;
  outline: none;
  resize: none;
  padding: 0;
  contain: strict;

  &:is(textarea) {
    overflow: hidden;
  }
}

.input__placeholder {
  grid-area: input;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  display: grid;
  color: var(--color-input-placeholder);
}

.input__actions {
  grid-area: actions;
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  align-items: center;
  gap: var(--gap-sm);
}

.input__icon {
  grid-area: icon;
  display: grid;
  width: 2.5rem;
  height: 2.5rem;
  place-items: center;
  place-self: center;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';
import { Project } from '/@features/project/project.types';
import { date } from '/@utilities/intl';

const props = defineProps<{ project: Project }>();

const emits = defineEmits(['close']);

const properties = computed(() => {
  const proj = props.project;
  if (!proj) return [];

  return [
    { label: 'Internnummer', value: proj.ref2, wide: true },
    {
      label: 'Kontrakts-/ordrenummer',
      value: proj.ref1,
      wide: true,
    },
    {
      label: 'Addresse',
      value: proj.address,
      wide: true,
    },
    {
      label: 'Status',
      value: proj.statusName,
    },
    {
      label: 'Ansvarlig montør',
      value: proj.installer,
    },
    {
      label: 'Saksbehandler',
      value: proj.caseworkerName,
    },
    ...(proj.department ? [{ label: 'Avdeling', value: proj.department}] : []),
    {
      label: 'Dato opprettet',
      value: date(proj.createdAt),
      wide: !!proj.department
    },
    {
      label: 'Beskrivelse',
      value: proj.description,
      wide: true,
    },
  ];
});
</script>

<template>
  <teleport to="#overlay">
    <div class="modal-container" v-on:click.self="$emit('close')">
      <div class="modal">
        <df-header>
          <p>{{ project.name }}</p>
          <template v-slot:actions>
            <df-button v-on:click="$emit('close')" class="close-button">
              <template v-slot:icon>
                <df-icon code="f00d"></df-icon>
              </template>
            </df-button>
          </template>
        </df-header>
        <section class="project-details">
          <dl>
            <div
              v-for="property in properties"
              class="entry"
              :key="property.label"
              :class="{ wide: property.wide }"
            >
              <dt>
                {{ property.label }}
              </dt>
              <dd>
                {{ property.value || '-' }}
              </dd>
            </div>
          </dl>
        </section>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.modal-container {
  background-color: var(--color-backdrop);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  position: absolute;
}

.modal {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
  display: grid;
  align-content: start;
  border-radius: var(--radius-sm);
  z-index: 5;
  position: fixed;
  top: 45%;
  width: 100%;

  animation-name: slidein;
  animation-duration: 0.25s;
}

p {
  margin: 14px 14px 0 14px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.project-details {
  display: grid;
  gap: var(--gap-md);
  padding: 14px;
}

dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  overflow: hidden;
}

.wide {
  grid-column: 1 / -1;
}

.entry {
  padding: var(--gap-md);
}

.entry:not(:nth-child(1)) {
  border-top: 1px solid var(--color-divider);
}

dt {
  font-size: 0.8rem;
  font-weight: 600;
}

dd {
  display: flex;
  gap: var(--gap-sm);

  @media (max-width: 1000px) {
    font-size: 0.85rem;
  }
}

@media (min-width: 900px) {
  .modal {
    top: 74vh;
    left: 50%;
    transform: translate(-50%, -50vh);
    width: 500px;
    height: auto;
    bottom: auto;
    animation-name: none;
  }

  .entries > .entry:last-child {
    border-bottom: none;
  }
}

.close-button {
  border: none !important;
}
</style>
